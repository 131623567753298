import React, { useCallback } from "react"
import { StyledNotesList } from "components/Notes/components/style"
import { Line, Div, Loader, Flex } from "@ikiru/talentis-fpc"
import { useAssignment } from "views/assignments/assignment-module/assignment-module.context"
import { getOnEditNoteSubmit } from "components/Notes/helpers"
import { NoteEditForm } from "components/Notes/components/forms/NoteEditForm"
import { AssignmentsEndpoints } from "setup/api/endpoints/endpoints"
import NoteAssignmentItem, { NoteType } from "./NoteAssignmentItem"
import { useModal } from "utils/hooks/use-modal"
import { ModalNames } from "setup/modal/modal.definitions"
import { generateLinks } from "components/Notes/components/forms/utils"
import { useNavigate } from "react-router-dom"
import { Pagination } from "components/functional/pagination/Pagination"

export type LinkNotePreviewType = {
  id: string | number
  label: string
  Icon: React.ReactElement
  onClick: () => void
}

const NotesList = () => {
  const {
    notes,
    setNotes,
    assignmentId,
    assignmentNotesInfo,
    notesPagination
  } = useAssignment()

  const { open, close } = useModal()
  const navigate = useNavigate()

  const onSubmit = useCallback(
    (noteId: string) =>
      getOnEditNoteSubmit(
        AssignmentsEndpoints.Notes,
        assignmentId,
        noteId,
        (item, currentNote) => {
          const updatedNote = {
            ...item.note,
            linkCreatedByUser: item.createdBy,
            linkUpdatedByUser: item.updatedBy
          }
          const newNotes = notes.map((item) => {
            if (item.id === updatedNote.id) {
              if (currentNote) {
                currentNote.noteDescription = updatedNote.noteDescription
                currentNote.noteTitle = updatedNote.noteTitle
                return currentNote
              } else {
                return updatedNote
              }
            } else {
              return item
            }
            //Delete workaround ^ after story ____ Replace with =>
            // item.id === updatedNote.id ? updatedNote : item
          })

          setNotes(newNotes)
          close(ModalNames.EditNoteItemData)
        }
      ),
    [assignmentId, notes, setNotes, close]
  )

  const noteItemOpenModal = useCallback(
    (note: NoteType) => {
      const {
        noteTitle,
        noteDescription,
        id,
        assignmentId,
        campaignId,
        personId,
        companyId
      } = note
      open(
        ModalNames.EditNoteItemData,
        <Div mb="xs">
          <NoteEditForm
            initialValues={{
              noteTitle,
              noteDescription
            }}
            notes={notes}
            projectId={assignmentId as string}
            setNotes={setNotes as any}
            noteId={id}
            links={generateLinks(note, navigate, true)}
            linkInitialValue={{
              assignment: assignmentId,
              campaign: campaignId,
              person: personId,
              company: companyId
            }}
            onSubmit={onSubmit(id)}
          />
        </Div>
      )
    },
    [navigate, notes, onSubmit, open, setNotes]
  )

  return (
    <StyledNotesList>
      {assignmentNotesInfo.isLoading ? (
        <Loader />
      ) : (
        <Div>
          {notes.map((note, index) => (
            <Div key={`note_${note?.id}`}>
              {index !== 0 && <Line variant="thin" />}
              <NoteAssignmentItem
                {...{
                  note,
                  links: generateLinks(note, navigate),
                  openModal: noteItemOpenModal
                }}
              />
            </Div>
          ))}
          {notesPagination.totalItemCount > 25 && (
            <Flex width="100%" justifyContent="center" mb={20}>
              <Pagination
                {...notesPagination}
                onPageChange={notesPagination.onPageChange}
              />
            </Flex>
          )}
        </Div>
      )}
    </StyledNotesList>
  )
}

export default React.memo(NotesList)

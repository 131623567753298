import React, { useCallback } from "react"
import { messages } from "setup/messages/messages"
import AssignmentNotesEditMode from "./components/EditMode"
import NotesList from "./components/NotesList"
import { useAssignment } from "views/assignments/assignment-module/assignment-module.context"
import { Button, Div, Section } from "@ikiru/talentis-fpc"
import { ModalNames } from "setup/modal/modal.definitions"
import { useModal } from "utils/hooks/use-modal"
import PersonSectionButton from "views/persons/components/person-components/PersonSectionButton"

const AssignmentNotes = () => {
  const { assignmentId, notes, setNotes, assignmentDetails } = useAssignment()

  const { open, close } = useModal()

  const noteOpenModal = useCallback(() => {
    open(
      ModalNames.EditNoteData,
      <AssignmentNotesEditMode
        {...{
          assignmentId,
          notes,
          setNotes,
          close,
          assignmentName: assignmentDetails.name
        }}
      />
    )
  }, [open, assignmentId, notes, setNotes, close, assignmentDetails.name])

  return (
    <Section
      size="xxSmall"
      header={{
        title: messages.person.note.title,
        actions: (
          <PersonSectionButton
            disabled={false}
            label={messages.person.note.newNote}
            onClick={noteOpenModal}
          />
        )
      }}
    >
      <Div py="s" width="100%">
        <NotesList />
      </Div>
    </Section>
  )
}

export default React.memo(AssignmentNotes)

import React, { useCallback } from "react"
import { getOnAddNoteSubmit } from "components/Notes/helpers"
import NotesEditMode from "components/Notes/components/EditMode"
import { CampaignsEndpoints } from "setup/api/endpoints/endpoints"
import { ModalNames } from "setup/modal/modal.definitions"
import { useModal } from "utils/hooks/use-modal"
import { CampaignNote } from "../../types"

type CampaignEditModeProps = {
  campaignId: string
  campaignName: string
  notes: CampaignNote[]
  setNotes: any
  close: (modalName: ModalNames) => void
}

const CampaignNotesEditMode = ({
  campaignId,
  campaignName,
  notes,
  setNotes
}: CampaignEditModeProps) => {
  const { close } = useModal()

  const onSubmit = useCallback(
    () =>
      getOnAddNoteSubmit(CampaignsEndpoints.Notes, campaignId, (item) => {
        const newNote = {
          ...item.note,
          campaign: { id: campaignId, name: campaignName },
          linkCreatedByUser: item.createdBy,
          linkUpdatedByUser: item.updatedBy
        }

        const newNotes = [newNote, ...notes]
        setNotes(newNotes)
        close(ModalNames.EditNoteData)
      }),
    [campaignId, campaignName, notes, setNotes, close]
  )

  return (
    <NotesEditMode
      onSubmit={onSubmit}
      close={() => close(ModalNames.EditNoteData)}
    />
  )
}

export default React.memo(CampaignNotesEditMode)

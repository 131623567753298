import React, { useEffect, useState } from "react"
import { Formik, FormikHelpers, FormikValues } from "formik"
import { addNoteValidationSchema } from "./schema"
import { NoteEditFormProps } from "./types"
import NoteFormLayout from "./NoteFormLayout"
import { NoteEditValues } from "../../types"
import { Button, Div, Flex, ModalSectionHeader } from "@ikiru/talentis-fpc"
import { StyledDiv, StyledForm } from "../style"
import { messages } from "setup/messages/messages"
import { useModal } from "utils/hooks/use-modal"
import { ModalNames } from "setup/modal/modal.definitions"
import LinkedNotesForm from "./LinkedNotesForm"
import { LinkNotePreviewType } from "views/assignments/components/assignment-notes/components/NotesList"
import PreviewLinks from "./components/PreviewLinks"
import { AssignmentNote } from "views/assignments/components/assignment-notes/types"

type FormReferenceType = ((instance: any) => void) &
  React.MutableRefObject<FormikValues>

type onSubmitType = (
  values: NoteEditValues,
  formikHelpers: FormikHelpers<NoteEditValues>
) => void

export const NoteEditForm = React.forwardRef(
  (props: NoteEditFormProps, ref) => {
    const [note, setNote] = useState<AssignmentNote>()
    const [links, setLinks] = useState<LinkNotePreviewType[]>(props.links)
    const [companyDetails, setCompanyDetails] = useState<{
      id: string
      name: string
    }>()

    const {
      onSubmit: onSubmitFromProps,
      initialValues,
      noteId,
      notes,
      setNotes,
      projectId,
      linkInitialValue
    } = props

    useEffect(() => {
      const currentNote = notes.find((noteItem) => noteItem.id === noteId)
      setNote(currentNote)
    }, [noteId, notes])

    const { close } = useModal()

    const updateNoteList = () => {
      const updatedNote = notes.map((noteItem) => {
        if (noteItem.id === note?.id) {
          const newNote = note

          return newNote
        }
        return noteItem
      })

      setNotes(updatedNote)
    }

    const onSubmit: onSubmitType = (values, actions) => {
      updateNoteList()
      onSubmitFromProps(
        {
          ...values,
          //Workaround delete after 131610
          currentNote: note
        },
        actions
      )
      close(ModalNames.EditNoteItemData)
    }

    return (
      <Flex
        justifyContent="center"
        alignItems="center"
        width="100vw"
        height="100vh"
      >
        <StyledDiv>
          <Formik
            innerRef={ref as FormReferenceType}
            initialValues={initialValues}
            validationSchema={addNoteValidationSchema}
            onSubmit={onSubmit}
          >
            {() => {
              return (
                <StyledForm>
                  <ModalSectionHeader
                    size="xSmall"
                    title={messages.person.note.editNote}
                    actions={
                      <>
                        <Button type="submit" size="small" mr="xxs">
                          {messages.form.generic.save}
                        </Button>
                        <Button
                          size="small"
                          mode="standard-white"
                          onClick={() => {
                            updateNoteList()
                            close(ModalNames.EditNoteItemData)
                          }}
                        >
                          {messages.generic.cancel}
                        </Button>
                      </>
                    }
                  />
                  <NoteFormLayout />
                </StyledForm>
              )
            }}
          </Formik>
          <PreviewLinks
            setNote={setNote}
            note={note!}
            setLinks={setLinks}
            links={links}
            noteId={noteId}
            companyDetails={companyDetails}
            projectId={projectId}
            {...linkInitialValue}
          />
          <Div minWidth="100%">
            <LinkedNotesForm
              setLinks={setLinks}
              noteId={noteId}
              setNote={setNote}
              note={note!}
              companyDetails={companyDetails}
              setCompanyDetails={setCompanyDetails}
              projectId={projectId}
              {...linkInitialValue}
            />
          </Div>
        </StyledDiv>
      </Flex>
    )
  }
)

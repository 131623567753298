import { Flex, NoteDocuments, NoteLinks } from "@ikiru/talentis-fpc"
import { generateNoteDate } from "components/NoteItem/helpers"
import { openNoteDoc } from "components/NoteItem/utlis"
import AttachmentModel from "components/PersonRecords/Notes/NoteModal/AttachmentModel"
import React, { useCallback, useMemo } from "react"
import { messages } from "setup/messages/messages"
import { ModalNames } from "setup/modal/modal.definitions"
import { RouterUrl } from "setup/router/routes"
import { formatDateTime } from "utils/format-date"
import { useModal } from "utils/hooks/use-modal"
import { LinkNotePreviewType } from "views/assignments/components/assignment-notes/components/NotesList"
import { NoteType } from "views/assignments/components/assignment-notes/components/NotesList/NoteAssignmentItem"

type NoteCompanyItemProps = {
  note: NoteType
  links: LinkNotePreviewType[]
}
const NoteCompanyItem = ({ note, links }: NoteCompanyItemProps) => {
  const { open } = useModal()
  const {
    noteTitle,
    noteDescription,
    createdBy,
    createdDate,
    updatedBy,
    updatedDate,
    linkAssignment,
    linkCampaign
  } = generateNoteDate(note)

  const projectRoute = useMemo(
    () =>
      linkAssignment?.id
        ? `${RouterUrl.AssignmentDetails}/${linkAssignment?.id}`
        : linkCampaign?.id
        ? `${RouterUrl.CampaignDetails}/${linkCampaign?.id}`
        : " ",
    [linkCampaign, linkAssignment]
  )

  const seeDocument = useCallback(
    (documentId: string) => {
      const document = note.documents?.find(
        (doc) => doc.id === documentId
      ) as NoteDocuments
      if (document) {
        openNoteDoc(document, documentId, note.id, () =>
          open(
            ModalNames.EmailAttachments,
            <AttachmentModel noteId={note.id} documentId={documentId} />
          )
        )
      }
    },
    [note.documents, note.id, open]
  )

  return (
    <Flex as="li" bg="grey.lightest" flexDirection="column" pb={0} m={0} my="s">
      <Flex>
        <NoteLinks
          title={noteTitle}
          description={noteDescription}
          createdDate={createdDate}
          createdBy={createdBy}
          formattedCreatedDate={formatDateTime(createdDate)}
          formattedUpdatedDate={
            updatedDate ? formatDateTime(updatedDate) : null
          }
          tag={linkAssignment?.name || linkCampaign?.name}
          tagVariant={linkCampaign?.name ? "grey-standard" : undefined}
          updatedBy={updatedBy}
          updatedDate={updatedDate}
          moreButtonLabel={messages.person.note.showFullNote}
          lessButtonLabel={messages.person.note.showLessNote}
          linesNumber={4}
          useArrow
          isHeaderFlexRow
          projectUrl={projectRoute}
          documents={note.documents as unknown as NoteDocuments[]}
          onDocumentClick={seeDocument}
          editModalButton={false}
          isJustifyContent
          links={links}
        />
      </Flex>
    </Flex>
  )
}

export default NoteCompanyItem

import React, { useCallback } from "react"
import { Flex, BodyText, Div, Toggle } from "@ikiru/talentis-fpc"
import { LinkNoteField } from "views/persons/components/person-notes/form/constants/definitions"
import { LinkNoteToggleStatus } from "../definitions"
import { messages } from "setup/messages/messages"

type ToggleLinkStatusProps = {
  disableAssignment: boolean
  disableCampaign: boolean
  disablePerson: boolean
  disableCompany: boolean
  linkNoteToggleStatus: LinkNoteToggleStatus
  setLinkNoteToggleStatus: React.Dispatch<
    React.SetStateAction<LinkNoteToggleStatus>
  >
}
const ToggleLinkStatus = ({
  disableAssignment,
  disableCampaign,
  disableCompany,
  disablePerson,
  linkNoteToggleStatus,
  setLinkNoteToggleStatus
}: ToggleLinkStatusProps) => {
  const handleChange = useCallback(
    (e: any) => {
      switch (e.currentTarget.value) {
        case LinkNoteToggleStatus.Assignment:
          setLinkNoteToggleStatus(LinkNoteToggleStatus.Assignment)
          break
        case LinkNoteToggleStatus.Campaign:
          setLinkNoteToggleStatus(LinkNoteToggleStatus.Campaign)
          break
        case LinkNoteToggleStatus.Person:
          setLinkNoteToggleStatus(LinkNoteToggleStatus.Person)
          break
        case LinkNoteToggleStatus.Company:
          setLinkNoteToggleStatus(LinkNoteToggleStatus.Company)
          break
        default:
          setLinkNoteToggleStatus(LinkNoteToggleStatus.None)
      }
    },
    [setLinkNoteToggleStatus]
  )

  return (
    <>
      <Flex alignItems="center" my="xs" flexWrap="wrap">
        <BodyText m="none" mr="xs">
          {messages.person.note.form.linkTo}:
        </BodyText>
        <Div mr="s">
          <Toggle
            small
            type="radio"
            hideUnderline
            notCheckedFade
            onChange={handleChange}
            value={LinkNoteToggleStatus.None}
            checked={linkNoteToggleStatus === LinkNoteToggleStatus.None}
            name={LinkNoteField.LinkNote}
            labelProps={{ ml: "xxs" }}
            label={messages.person.note.form.none}
          />
        </Div>
        <Div mr="s">
          <Toggle
            small
            type="radio"
            hideUnderline
            notCheckedFade
            disabled={disableAssignment}
            onChange={handleChange}
            value={LinkNoteToggleStatus.Assignment}
            checked={linkNoteToggleStatus === LinkNoteToggleStatus.Assignment}
            name={LinkNoteField.LinkNote}
            labelProps={{ ml: "xxs" }}
            label={messages.person.note.form.assignment}
          />
        </Div>
        <Div mr="s">
          <Toggle
            small
            type="radio"
            hideUnderline
            notCheckedFade
            onChange={handleChange}
            disabled={disableCampaign}
            value={LinkNoteToggleStatus.Campaign}
            checked={linkNoteToggleStatus === LinkNoteToggleStatus.Campaign}
            name={LinkNoteField.LinkNote}
            labelProps={{ ml: "xxs" }}
            label={messages.person.note.form.campaign}
          />
        </Div>
        <Div mr="s">
          <Toggle
            small
            type="radio"
            hideUnderline
            notCheckedFade
            disabled={disablePerson}
            onChange={handleChange}
            value={LinkNoteToggleStatus.Person}
            checked={linkNoteToggleStatus === LinkNoteToggleStatus.Person}
            name={LinkNoteField.LinkNote}
            labelProps={{ ml: "xxs" }}
            label={messages.person.note.form.person}
          />
        </Div>
        <Div mr="s">
          <Toggle
            small
            type="radio"
            hideUnderline
            notCheckedFade
            disabled={disableCompany}
            onChange={handleChange}
            value={LinkNoteToggleStatus.Company}
            checked={linkNoteToggleStatus === LinkNoteToggleStatus.Company}
            name={LinkNoteField.LinkNote}
            labelProps={{ ml: "xxs" }}
            label={messages.person.note.form.company}
          />
        </Div>
      </Flex>
    </>
  )
}

export default ToggleLinkStatus

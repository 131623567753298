import { Section, Div, Flex, Loader } from "@ikiru/talentis-fpc"
import React from "react"
import { messages } from "setup/messages/messages"
// import PersonSectionButton from "views/persons/components/person-components/PersonSectionButton"
import CompanyNotesList from "./components/CompanyNotesList"

type CompanyNotesProps = {
  isLoading: boolean
}
const CompanyNotes = ({ isLoading }: CompanyNotesProps) => {
  return (
    <Section
      size="xxSmall"
      header={{
        title: messages.person.note.title
        // actions: (
        //   <PersonSectionButton
        //     disabled={false}
        //     label={messages.person.note.newNote}
        //     onClick={() => {}}
        //   />
        // )
      }}
    >
      {isLoading ? (
        <Flex>
          <Loader />
        </Flex>
      ) : (
        <Div py="s" width="100%">
          <CompanyNotesList />
        </Div>
      )}
    </Section>
  )
}

export default React.memo(CompanyNotes)

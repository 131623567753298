import { apiRequest } from "setup/api/api"
import { expandEndpointByParams } from "setup/api/api.helpers"
import { callWithErrorMessages } from "utils/forms/forms"
import { GetOnAddNotesSubmitType, GetOnEditNotesSubmitType } from "./types"
import { skipErrorHeader } from "setup/api/utils/skip-error-header"

export const getOnEditNoteSubmit: GetOnEditNotesSubmitType =
  (endpoint, id, noteId, onSubmit) => async (values, helpers) => {
    const { setFieldError } = helpers

    const [error, response] = await apiRequest.put({
      endpointParams: { id: id, noteId },
      endpoint: endpoint,
      config: {
        ...expandEndpointByParams({
          expand: ["createdByUser", "updatedByUser"]
        }),
        headers: {
          ...skipErrorHeader
        }
      },
      data: {
        title: values.noteTitle,
        description: values.noteDescription
      }
    })

    if (error) {
      callWithErrorMessages(setFieldError, error)
      helpers.setSubmitting(false)
    } else {
      onSubmit(response?.data, values.currentNote)
    }
  }

export const getOnAddNoteSubmit: GetOnAddNotesSubmitType =
  (endpoint, id, onSubmit) => async (values, helpers) => {
    const { setFieldError } = helpers

    const [error, response] = await apiRequest.post({
      endpointParams: { id: id },
      endpoint: endpoint,
      config: {
        ...expandEndpointByParams({
          expand: ["createdByUser", "updatedByUser"]
        }),
        headers: {
          ...skipErrorHeader
        }
      },
      data: {
        title: values.noteTitle,
        description: values.noteDescription
      }
    })

    if (error) {
      callWithErrorMessages(setFieldError, error)
      helpers.setSubmitting(false)
    } else {
      onSubmit(response?.data)
    }
  }

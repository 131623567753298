import { Badge, H4 } from "@ikiru/talentis-fpc"
import styled from "styled-components"

export const ProjectSectionHeader = styled(H4)`
  font-size: 18px;
  margin: 0px;
  color: ${({ theme }) => theme.colors.grey.dark};
`
export const ProjectSectionBadge = styled(Badge)`
  margin-left: ${({ theme }) => theme.space.xxs}px;
  border-radius: 5px;
  padding-top: 2px;
  height: 24px;
  background-color: ${({ theme }) => theme.colors.grey.standard};
  color: ${({ theme }) => theme.colors.white.standard};
`

import { Div, Line, Flex } from "@ikiru/talentis-fpc"
import { Pagination } from "components/functional/pagination/Pagination"
import { generateLinks } from "components/Notes/components/forms/utils"
import { StyledNotesList } from "components/Notes/components/style"
import React from "react"
import { useNavigate } from "react-router-dom"
import { useCompany } from "views/companies/company-module/company-module.context"
import NoteCompanyItem from "./NoteCompanyItem"

const CompanyNotesList = () => {
  const { notes, notesPagination } = useCompany()

  //    const { open, close } = useModal()
  //     const navigate = useNavigate()

  //       const onSubmit = useCallback(
  //         (noteId: string) =>
  //           getOnEditNoteSubmit(
  //             AssignmentsEndpoints.Notes,
  //             assignmentId,
  //             noteId,
  //             (item, currentNote) => {
  //               const updatedNote = {
  //                 ...item.note,
  //                 linkCreatedByUser: item.createdBy,
  //                 linkUpdatedByUser: item.updatedBy
  //               }
  //               const newNotes = notes.map((item) => {
  //                 if (item.id === updatedNote.id) {
  //                   if (currentNote) {
  //                     currentNote.noteDescription = updatedNote.noteDescription
  //                     currentNote.noteTitle = updatedNote.noteTitle
  //                     return currentNote
  //                   } else {
  //                     return updatedNote
  //                   }
  //                 } else {
  //                   return item
  //                 }
  //                 //Delete workaround ^ after story ____ Replace with =>
  //                 // item.id === updatedNote.id ? updatedNote : item
  //               })

  //               setNotes(newNotes)
  //               close(ModalNames.EditNoteItemData)
  //             }
  //           ),
  //         [assignmentId, notes, setNotes, close]
  //       )
  const navigate = useNavigate()
  return (
    <StyledNotesList>
      <Div>
        {notes.map((note, index) => (
          <Div key={`note_${note?.id}`}>
            {index !== 0 && <Line variant="thin" />}
            <NoteCompanyItem
              {...{
                note,
                links: generateLinks(note, navigate),
                openModal: () => {}
              }}
            />
          </Div>
        ))}
        {notesPagination.totalItemCount > 25 && (
          <Flex width="100%" justifyContent="center" mb={20}>
            <Pagination
              {...notesPagination}
              onPageChange={notesPagination.onPageChange}
            />
          </Flex>
        )}
      </Div>
    </StyledNotesList>
  )
}

export default CompanyNotesList

import React from "react"
import { ProjectSectionBadge, ProjectSectionHeader } from "./style"
import { Flex, H5, theme } from "@ikiru/talentis-fpc"

type TabSectionHeaderProp = {
  label: string
  count?: number
}
const TabSectionHeader = ({ label, count }: TabSectionHeaderProp) => {
  return (
    <Flex alignItems="center" mt="-10px">
      <ProjectSectionHeader>{label}</ProjectSectionHeader>
      {Boolean(count) && (
        <ProjectSectionBadge>
          <H5 m="0" p="0" color={theme.colors.white.standard}>
            {count}
          </H5>
        </ProjectSectionBadge>
      )}
    </Flex>
  )
}

export default TabSectionHeader

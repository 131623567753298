import React, { useCallback } from "react"
import { PersonNote } from "views/persons/components/person-notes/types"
import { PersonNoteEditValues } from "../form/constants/types"
import { getOnEditPersonNoteSubmit } from "../form/actions"
import { Div, Flex, Line, SelectOptionProps } from "@ikiru/talentis-fpc"
import { PersonNotesEditForm } from "../form/PersonNotesEditForm"
import { FormAction } from "utils/forms/form.types"
import { List, NoteListContainer } from "./style"
import { personNotesFormE2ETargets } from "../form/constants/definitions"
import PersonNoteItem from "./PersonNoteItem"
import { useModal } from "utils/hooks/use-modal"
import { ModalNames } from "setup/modal/modal.definitions"
import PersonNoDataScreen from "../../person-no-data-message/PersonNoDataScreen"
import { messages } from "setup/messages/messages"
import { NoteEditForm } from "components/Notes/components/forms/NoteEditForm"
import { usePerson } from "views/persons/person-module/candiate-module.context"
import { generateLinks } from "components/Notes/components/forms/utils"
import { useNavigate } from "react-router-dom"
import { PersonsEndpoints } from "setup/api/endpoints/endpoints"
import { getOnEditNoteSubmit } from "components/Notes/helpers"
import { Pagination } from "components/functional/pagination/Pagination"

type PersonNotesListProps = {
  notes: PersonNote[]
  personId: string
  editPersonNote: (note: PersonNote) => void
  assignmentOptions: SelectOptionProps[]
  campaignOptions: SelectOptionProps[]
}

export const PersonNotesList = ({ notes, personId }: PersonNotesListProps) => {
  const { setNotes, notesPagination } = usePerson()
  const navigate = useNavigate()
  const { open: openModal, close } = useModal()

  const onSubmit = useCallback(
    (noteId: string) =>
      getOnEditNoteSubmit(
        PersonsEndpoints.Notes,
        personId,
        noteId,
        (item, currentNote) => {
          const updatedNote = {
            ...item.note,
            linkCreatedByUser: item.createdBy,
            linkUpdatedByUser: item.updatedBy
          }
          const newNotes = notes.map((item) => {
            if (item.id === updatedNote.id) {
              if (currentNote) {
                currentNote.noteDescription = updatedNote.noteDescription
                currentNote.noteTitle = updatedNote.noteTitle
                return currentNote
              } else {
                return updatedNote
              }
            } else {
              return item
            }
            //Delete workaround ^ after story ____ Replace with =>
            // item.id === updatedNote.id ? updatedNote : item
          })

          setNotes(newNotes as PersonNote[])
          close(ModalNames.EditNoteItemData)
        }
      ),
    [personId, notes, setNotes, close]
  )

  const openEditModal = (note: PersonNote) => {
    const {
      noteTitle,
      noteDescription,
      id,
      assignmentId,
      campaignId,
      companyId
    } = note
    openModal(
      ModalNames.EditNoteItemData,
      <Div mb="xs">
        <NoteEditForm
          initialValues={{
            noteTitle,
            noteDescription
          }}
          notes={notes}
          projectId={personId}
          setNotes={setNotes as any}
          noteId={id}
          links={generateLinks(note, navigate, true)}
          linkInitialValue={{
            assignment: assignmentId,
            campaign: campaignId,
            person: personId,
            company: companyId
          }}
          onSubmit={onSubmit(id)}
        />
      </Div>
    )
  }

  return (
    <Flex width="100%">
      {notes.length ? (
        <Div width="100%">
          {notes.map((note, index) => {
            return (
              <Div key={`note_${note?.id}`}>
                {index !== 0 && <Line variant="thin" />}
                <PersonNoteItem
                  note={note}
                  index={index}
                  links={generateLinks(note, navigate)}
                  editNote={openEditModal}
                  buttonEditDataE2ETarget={personNotesFormE2ETargets.editNote}
                />
              </Div>
            )
          })}
          {notesPagination.totalItemCount > 25 && (
            <Flex width="100%" justifyContent="center" mb={20}>
              <Pagination
                {...notesPagination}
                onPageChange={notesPagination.onPageChange}
              />
            </Flex>
          )}
        </Div>
      ) : (
        <PersonNoDataScreen
          message={messages.person.personTabSection.noDataTexts.noNotes}
        />
      )}
    </Flex>
  )
}

import React, { SyntheticEvent } from "react"
import { Div, Flex, Tag } from "@ikiru/talentis-fpc"
import ProjectActionPopUp from "components/ProjectActions/ProjectActionPopUp"
import { useCampaign } from "views/campaigns/campaign-module/campaign-module.context"
import { Contact } from "views/campaigns/components/contacts/type"
import {
  InterviewProgressDetailsBox,
  ProjectBoxWrapper,
  ProjectFooterBox,
  StatusCircle,
  StatusContainer,
  StatusTexts
} from "views/persons/components/person-assignments/components/AssignmentRecord/style"
import { InterviewProgress } from "../InterviewProgress/InterviewProgress"
import { RouterUrl } from "setup/router/routes"
import { Link } from "react-router-dom"
import { messages } from "setup/messages/messages"
import { get } from "lodash"
import { CampaignBox, CampaignDetailsBox } from "./style"
import { usePersonRecordNotes } from "components/PersonRecords/Notes/hooks/usePersonRecordNotes"
import { useTagsContacts } from "../../hooks/useTagsContacts"
import { Tags } from "components/PersonRecords/Tags"
import { PersonRecordNotes } from "components/PersonRecords/Notes"
import { usePerson } from "views/persons/person-module/candiate-module.context"
import { Note } from "components/NoteItem/types"
import { PersonNote } from "views/persons/components/person-notes/types"

type AssignmentRecordProps = {
  contact: Contact
}

const CampaignRecord = ({ contact }: AssignmentRecordProps) => {
  const { setSelectedContactId } = useCampaign()

  const { personId, profileDetails, setNotes, notes } = usePerson()

  const campaignDetails = contact.linkCampaign
  const updateNotes = (newNotes: Note) => {
    setNotes([newNotes as PersonNote, ...notes])
  }

  const { note, onCreateNoteSubmit, onEditNoteSubmit, openNoteModalCallback } =
    usePersonRecordNotes(
      personId,
      profileDetails.name.value || "",
      campaignDetails.id,
      campaignDetails.name,
      "campaign",
      updateNotes
    )

  const options = [
    {
      label: messages.person.note.title,
      onClick: () => {
        openNoteModalCallback(
          "",
          campaignDetails.name,
          messages.assignment.addNew
        )()
      }
    },
    {
      label: contact ? (
        <Tags
          personRecordTags={contact.tags}
          tags={campaignDetails.tags}
          personRecordId={contact.id}
          usePersonRecordTags={useTagsContacts}
          name={campaignDetails.name}
          showOnlyAddButton={true}
          outputOnlyLabel={true}
          label={messages.assignment.assignmentTags}
        />
      ) : (
        ""
      ),
      onClick: ((e: SyntheticEvent) => {
        const label = (e.target as HTMLElement)?.querySelector(
          "#tags-label"
        ) as HTMLElement
        label?.click()
        e.preventDefault()
        e.stopPropagation()
        e.nativeEvent.stopImmediatePropagation()
      }) as VoidFunction,
      disableClose: true
    }
  ]

  return (
    <ProjectBoxWrapper key={contact.id}>
      <CampaignBox>
        <CampaignDetailsBox>
          <Flex
            flexDirection="column"
            width="100%"
            alignItems="flex-start"
            mt="xs"
          >
            <Tag width="100%" variant="grey-standard" mb="xxs">
              <Link
                onClick={() => setSelectedContactId("")}
                to={`${RouterUrl.CampaignDetails}/${contact?.linkCampaign?.id}`}
              >
                {contact.linkCampaign?.name}
              </Link>
            </Tag>
            <StatusContainer>
              <StatusCircle />
              <StatusTexts>
                {get(
                  messages.assignment.statuses,
                  campaignDetails?.status || "active"
                )}
              </StatusTexts>
            </StatusContainer>
          </Flex>
        </CampaignDetailsBox>
        <InterviewProgressDetailsBox>
          <Div maxWidth="500px">
            <InterviewProgress contact={contact} />
          </Div>
          <Flex flexDirection="column" alignItems="flex-end" pl="m">
            <ProjectActionPopUp options={options} />
          </Flex>
        </InterviewProgressDetailsBox>
      </CampaignBox>
      <ProjectFooterBox>
        {note && (
          <PersonRecordNotes
            note={note}
            name={campaignDetails.name}
            onEdit={onEditNoteSubmit}
            onCreate={onCreateNoteSubmit}
            typeTitle={messages.assignment.addNew}
            showAddIcon={false}
            showMostRecent={false}
          />
        )}
        {contact && Boolean(contact.tags.length) && (
          <Tags
            personRecordTags={contact.tags}
            tags={campaignDetails.tags}
            personRecordId={contact.id}
            usePersonRecordTags={useTagsContacts}
            name={campaignDetails.name}
            showAddIcon={false}
          />
        )}
      </ProjectFooterBox>
    </ProjectBoxWrapper>
  )
}

export default CampaignRecord
